import { ResponsiveHeader } from '@/components/new/ResponsiveHeader';
import { BaseStyle } from '@/components/ui/BaseStyle';
import { Image } from '@/components/ui/Image';
import logo from '@images/logo.svg';
import styled, { createGlobalStyle } from 'styled-components';
import { HelpMenu } from '../Header/HelpMenu';
import { NewsMenu } from '../Header/NewsMenu';
import { HowToUseStorylaneModal } from '../HowToUseStorylaneModal';

const headerHeight = '40px';

const GlobalStyle = createGlobalStyle`
${BaseStyle}
html {
  background-color: #EFF1F4;
}
`;

const Wrapper = styled.div`
  height: 100vh;
  padding-top: ${headerHeight};
`;

const Anchor = styled.a`
  display: inline-flex;
  text-decoration: none;
`;

export type ResponsiveStaticPageLayoutProps = {
	children: React.ReactNode;
};
export const ResponsiveStaticPageLayout = ({
	children,
}: ResponsiveStaticPageLayoutProps): JSX.Element => (
	<>
		<GlobalStyle />
		<Wrapper>
			<ResponsiveHeader.Root>
				<ResponsiveHeader.Menu />
				<ResponsiveHeader.Name>
					<Anchor href="/">
						<Image
							src={logo}
							width={238}
							height={20}
							alt="Money Forward Pay for Business"
						/>
					</Anchor>
				</ResponsiveHeader.Name>
				<ResponsiveHeader.Item>
					<NewsMenu />
					<HelpMenu />
				</ResponsiveHeader.Item>
			</ResponsiveHeader.Root>
			<HowToUseStorylaneModal />
			<main>{children}</main>
		</Wrapper>
	</>
);
