import { Login } from '@/components/screen';
import { ResponsiveStaticPageLayout } from '@/components/screen/layouts/ResponsiveStaticPageLayout';

import type { PageWithLayout } from '@/lib/page';

const LoginPage: PageWithLayout = () => <Login />;

LoginPage.displayName = 'LoginPage';
LoginPage.getLayout = (page) => (
	<ResponsiveStaticPageLayout>{page}</ResponsiveStaticPageLayout>
);

export default LoginPage;
